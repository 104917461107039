// disable eslint

import axios from "../http-common";
import authHeader from "./auth-header";
import { auth } from "@/store/auth.module";

class UserService {
  checkToken() {
    return axios.get("/check_token", { headers: authHeader() });
  }

  getProjects(status: string) {
    return axios.post(
      "/project/get_projects",
      {
        // department: user.department ? user.department.name : "",
        // subDep: user.subdepartment ? user.subdepartment : "",
        status: status,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getProjectByNumber(project_number: string) {
    return axios.post(
      `/project/get_project_by_number`,
      {
        project_number: project_number,
      },
      { headers: authHeader() }
    );
  }

  // Miscellaneous
  filterProjects(status: string, filtr: string) {
    return axios.post(
      "/project/filter_projects",
      {
        status: status,
        filter: filtr,
      },
      {
        headers: authHeader(),
      }
    );
  }

  async getDepartments() {
    const response = await axios.get("/get_departments");
    return response.data;
  }

  async getProjectSizes() {
    const response = await axios.get("/project/project_size");
    return response.data;
  }

  getUserData() {
    return axios.get("/user", { headers: authHeader() });
  }

  // Time Sheet API

  checkIn(activityName: string, project: any, categoryName: string) {
    return axios.post(
      "/entry/checkin",
      {
        activity: activityName,
        projectName: project ? project.name : "",
        projectNumber: project ? project.number : "",
        category: categoryName,
      },
      {
        headers: authHeader(),
      }
    );
  }

  checkOut() {
    return axios.post("/entry/checkout", {}, { headers: authHeader() });
  }

  // Proposals / Tendering

  createProposal(data: any, attachments: File[] | undefined) {
    const formData = new FormData();

    if (attachments !== undefined) {
      for (const attachment of attachments) {
        console.log(attachment);
        formData.append(attachment.name, attachment);
      }
    }

    formData.append("data", JSON.stringify(data));

    // using fetch because axios had problems
    return fetch("/api/project/create_proposal", {
      method: "POST",
      headers: {
        Authorization: authHeader().Authorization,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return { data: data };
      });
  }

  //! edit here
  editProposal(id: number, data: any, attachments: Blob[] | undefined) {
    const formData = new FormData();

    if (attachments !== undefined) {
      formData.append("files", JSON.stringify(attachments));
    }

    formData.append("data", JSON.stringify(data));

    // using fetch because axios had problems
    return fetch(`/api/project/edit_proposal/${id}`, {
      method: "POST",
      headers: {
        Authorization: authHeader().Authorization,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return { data: data };
      });
  }

  // duplicate proposal
  duplicateProposal(id: number, data: any, attachments: Blob[] | undefined) {
    const formData = new FormData();

    if (attachments !== undefined) {
      formData.append("files", JSON.stringify(attachments));
    }

    formData.append("data", JSON.stringify(data));

    // using fetch because axios had problems
    return fetch(`/api/project/duplicate_proposal/${id}`, {
      method: "POST",
      headers: {
        Authorization: authHeader().Authorization,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return { data: data };
      });
  }

  deleteProposal(id: number) {
    return axios.delete(`/project/edit_proposal/${id}`, {
      headers: authHeader(),
    });
  }

  getProposal(id: number) {
    return axios.get(`/project/edit_proposal/${id}`, { headers: authHeader() });
  }

  // Authorities

  async checkUserAuthorityAccess() {
    const response = await axios.get("/authority/authorization/", {
      headers: authHeader(),
    });
    return response.data.is_authorized;
  }

  // createAuthorityEntry(data: any) {
  //   return axios.post(
  //     "/authority/entry",
  //     {
  //       projectNumber: data.projectNumber,
  //       projectName: data.projectName,
  //       authority_activity: data.authority_activity,
  //       authority_stage: data.authority_stage,
  //       authority_name: data.authority_name,
  //       remarks: data.remarks,
  //       entry_date: data.entry_date,
  //     },
  //     {
  //       headers: authHeader(),
  //     }
  //   )
  // }

  createAuthorityEntry(data: any, attachments: File[] | undefined) {
    const formData = new FormData();

    if (attachments !== undefined) {
      for (const attachment of attachments) {
        formData.append(attachment.name, attachment);
      }
    }

    formData.append("data", JSON.stringify(data));

    return fetch("/api/authority/entry", {
      method: "POST",
      headers: {
        Authorization: authHeader().Authorization,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return { data: data };
      });

    // return axios.post(
    //   "/authority/entry",
    //   {
    //     projectNumber: data.projectNumber,
    //     projectName: data.projectName,
    //     authority_activity: data.authority_activity,
    //     authority_stage: data.authority_stage,
    //     authority_name: data.authority_name,
    //     remarks: data.remarks,
    //     entry_date: data.entry_date,
    //   },
    //   {
    //     headers: authHeader(),
    //   }
    // )
  }

  updateAuthorityProject(data: any) {
    return axios.post(
      `/authority/project/${data.id}`,
      {
        project_size: data.size,
        reference_number: data.reference_number,
        plot_number: data.plot_number,
      },
      {
        headers: authHeader(),
      }
    );
  }

  updateCoordinationStage(data: any) {
    return axios.post(
      `/authority/coordination_stage/${data.project_id}`,
      {
        selected_authorities_id: data.selected_authorities_id,
      },
      {
        headers: authHeader(),
      }
    );
  }

  async getCoordinationStage(project_id: number) {
    const response = await axios.get(
      `/authority/coordination_stage/${project_id}`,
      { headers: authHeader() }
    );
    return response.data;
  }

  async getProjectAuthorityEntries(project_id: number) {
    const response = await axios.get(
      `/authority/project_history/${project_id}`,
      { headers: authHeader() }
    );
    return response.data;
  }

  //   getModeratorBoard() {
  //     return axios.get(API_URL + 'mod', { headers: authHeader() });
  //   }

  //   getAdminBoard() {
  //     return axios.get(API_URL + 'admin', { headers: authHeader() });
  //   }
}

export default new UserService();
