interface User {
  access_token: string;
}

interface AuthHeader {
  Authorization?: string;
}

export default function authHeader(): any {
  const user: User = JSON.parse(localStorage.getItem("user") || "{}");

  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
}
