<template>
  <div id="app">
    <!-- <img src="./assets/fd-logo.png" alt width="200" height="200" style="margin-bottom: 40px;" /> -->
    <img src="/static/imgs/fd-logo.png" alt width="200" height="200" style="margin-bottom: 30px;" />
    <br />
    <div style="display: inline-block; margin-right: 10px;" v-if="loggedIn">
      <router-link to="/">Time Record</router-link>
    </div>
    <div
      style="display: inline-block; margin-right: 10px;"
      v-if="loggedIn && user.department.name == 'Tendering'"
    >
      |
      <router-link to="/create_proposal">Create Proposal</router-link>
    </div>
    <div
      style="display: inline-block; margin-right: 10px;"
      v-if="loggedIn && user.department.name == 'Tendering'"
    >
      |
      <router-link to="/proposal_projects">Proposal Projects</router-link>
    </div>
    <div
      style="display: inline-block; margin-right: 10px;"
      v-if="loggedIn"
    >
      |
      <router-link to="/authorities">Authorities</router-link>
    </div>
    <br />
    <router-view />
    <hr />
    <button
      class="btn btn-success"
      style="margin-bottom: 16px;"
      id="admin-link"
      onclick="location.href='/admin';"
    >Go To Admin Page</button>
    <br />
    <button v-if="loggedIn" class="btn btn-warning" id="logout" @click="logout()">Logout</button>
  </div>
</template>

<script>
import { Vue, Watch } from "vue-property-decorator";
import UserService from "./services/user.service";

// export default class App extends Vue {}
export default {
  name: "app",
  data() {
    return {
      user: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  mounted() {
    this.getLoggedinData();
  },
  watch: {
    $route: function(val, oldValue) {
      this.getLoggedinData();
    },
  },
  methods: {
    logout() {
      if (this.loggedIn) {
        // checkout user from latest activity if already checked in
        UserService.checkOut()
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.error(error);
          }
        )
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      }
    },
    getLoggedinData() {
      if (this.loggedIn) {
        UserService.checkToken().then(
          (response) => {
            this.user = response.data.logged_in_as;
          },
          (error) => {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        );
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}

.center-screen {
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 20vh;
}

.custom-btn {
  margin-top: 30px;
  width: 50%;
  height: 50px;
  font-size: 18px;
}

#admin-link a {
  color: inherit;
}
</style>
