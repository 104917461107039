import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueRouter);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/create_proposal",
    name: "Create Proposal",
    component: () => import("../views/create_proposal.vue"),
  },
  {
    path: "/edit_proposal/:id",
    name: "edit Proposal",
    component: () => import("../views/edit_proposal.vue"),
  },
  {
    path: "/proposal_projects",
    name: "Proposal Projects",
    component: () => import("../views/proposal_projects.vue"),
  },
  {
    path: "/authorities",
    name: "Authorities",
    component: () => import("../views/authorities.vue"),
  },
  {
    path: "/",
    name: "Time Entry",
    component: () => import("../views/timeapp.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
