import axios from "../http-common";
import User from "../models/user";

class AuthService {
  public login(user: User) {
    return axios
      .post("/user/login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        } else if (response.data.error) {
          return Promise.reject(response.data.error);
        }

        return response.data;
      });
  }

  public logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
