import axios from "axios";

const server = {
  // host: "127.0.0.1",
  host: "localhost",
  port: "5000",
};

export default axios.create({
  // baseURL: `http://${server.host}:${server.port}/api`,
  baseURL: "/api",
  headers: {
    post: {
      "Content-type": "application/json",
    },
  },
});
